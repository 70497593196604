import {
  type ErrorResponse as RemixError,
  isRouteErrorResponse,
} from '@remix-run/react';
import { Title } from '@ck/ui';
import { Detail } from '../../ui/src/atoms/typography/detail';
import { Heading } from '../../ui/src/atoms/typography/heading';
import * as Sentry from '@sentry/remix';

// The same as the RFC9457Exception type in the API
type RFC9457Exception = {
  name: string;
  type: string;
  status: number;
  title: string;
};

function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error;
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }
  console.error('Unable to get error message for error', error);
  return 'Unknown Error';
}

function getErrorDetail(error: { status?: number; data?: RFC9457Exception }) {
  if (!error.data) return '';
  if (typeof error.data === 'string') return error.data;
  return error.data.title || error.data.status || JSON.stringify(error.data);
}

export function GeneralErrorBoundry({ error }: { error: unknown }) {
  if (typeof document !== 'undefined') {
    console.error(error);
  }

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <div className="flex flex-col items-center p-20">
        {isRouteErrorResponse(error) ? (
          <div className="flex flex-col items-center">
            <img src="/404.webp" alt="404" className="h-[40vh]" />
            <Title className="flex justify-center">OOPS!</Title>
            <Heading size="h5">
              Vi kunne ikke finne siden du lette etter
            </Heading>
          </div>
        ) : (
          <div className="p-4">
            <h1 className="text-2xl">{getErrorMessage(error)}</h1>
          </div>
        )}
      </div>
    );
  }

  Sentry.captureException(error);
  return (
    <div className="flex items-center justify-center p-20">
      {isRouteErrorResponse(error) ? (
        <div className="flex flex-col items-center">
          <img src="/500.webp" alt="500" className="h-[40vh]" />
          <Title className="flex justify-center">OOPS!</Title>
          <Heading size="h5">Det oppstod en feil på serveren</Heading>
          <Detail>
            {error.status} - {getErrorDetail(error as RemixError)}
          </Detail>
        </div>
      ) : (
        <div className="p-4">
          <img src="/500.webp" alt="500" className="h-[40vh]" />
          <h1 className="text-2xl">{getErrorMessage(error)}</h1>
        </div>
      )}
    </div>
  );
}
